<template>
  <div class="box">
    <h1>财务报表审计</h1>
    <div class="topBox">
      <div>
          <div>
            <p>申查企业提供的报表科目余额表等财务信息</p>
            <img :src="core" alt="">
          </div>
          <div>
            <p>制作审计底稿，期间可能涉及凭证抽查</p>
            <img :src="core" alt="">
          </div>
      </div>
      <div>
          <div>
            <p>出具审计报告和管理建议书</p>
            <img :src="core" alt="">
          </div>
          <div>
            <p>专家解读</p>
          </div>
      </div>
      <p class="des">
        各种专项审计，包括并不限于高新技术企业认定（复审)专项审计，银行货款专项审计，科委、经信委、发改委等政府部门补助资金项目验收专项审计，科创文创等项目申请所需专项审计，投资过程中的尽职调查审计
      </p>
    </div>
    <div class="bottomBox">
      <div class="lift">
        <h5>《账务南计报告》</h5>
        <p>出具审计报告，主要包括审计后的资产负债表、利润表、现金流量表；展示调整分录并说明调整原因及后续规范操作方法；</p>
      </div>
      <div class="right">
        <h5>《管理建议书》</h5>
        <p>给出管理建议：对审计中发现的管理问题予以提示并给出建议；</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
          core:"https://attached-file.oss-cn-shanghai.aliyuncs.com/aila3.0/appletsImages/digitalbusinessCard/association/next_core.png"
        }
    }
}
</script>

<style scoped lang="less">
  
  .box{
    width: 1300px;
    margin: auto;
    padding-top:65px;
    padding-bottom:57px;
  }
  h1{
    font-size: 30px;
    font-weight: bold;
    color: #B6512E;
    line-height: 24px;
    text-align: center;
  }
  .topBox{
    background: #EEEEEE;
    padding-top: 49px;
    margin-top: 30px;
    border-bottom: 1px solid #FFFFFF;
  }
  .topBox>div{
    display: flex;
    align-items: center;
  }
  .topBox>div>div{
    display: flex;
    align-items: center;
    width: 50%;
    padding-left: 90px;
  }
  .topBox>div>div>p{
    width: 245px;
    font-size: 24px;
    font-weight: bold;
    color: #211D1D;
    line-height: 40px;
  }
  .topBox>div>div>img{
    margin-left: 107px;
    width: 14px;
    height: 16px;
  }
  .topBox>div:nth-child(2){
    margin-top: 62px;
  }
  .des{
    font-size: 22px;
    font-weight: 500;
    color: #211D1D;
    line-height: 40px;
    padding:56px 95px 46px 95px ;
  }
  .bottomBox{
    display: flex;
    background: #EEEEEE;
    height: 305px;
    >div{
      width:50%;
      height: 100%;
      padding-top:49px;
      padding-left: 95px;
      h5{
        font-size: 24px;
        font-weight: bold;
        color: #211D1D;
        line-height: 24px;
        padding-bottom:46px;
      }
      p{
        width: 406px;
        font-size: 22px;
        font-weight: 500;
        color: #211D1D;
        line-height: 40px;
      }
    }
    .lift{
      border-right:1px solid #FFFFFF;
    }
  }
  
</style>