<template>
  <div>
      <div class="light">
          <h1>产品亮点</h1>
          <div>
            <div>
              <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/serverSeven/light1.png" alt="">
              <span>降低系统性风险</span>
            </div>
            <div style="margin-left:216px">
              <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/serverSeven/light2.png" alt="">
              <span style="color: #F5CA5E;">优化财税结构</span>
            </div>
          </div>
      </div>
      <div class="desBox">
        <h1>产品介绍</h1>
        <div>
          <div class="txtBox">
              <p>优化企业基础框架，并给出股杈设立及转让中的税收建议</p>
              <p>梳理各业务链条，设定相应的应用场景</p>
              <p>完善各企业之间的业务链条关联（证据链完善）</p>
              <p>隔离主体公司的业务及财务风险</p>
          </div>
          <div class="imgBox">
            <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/serverSeven/des.png" alt="">
          </div>
          
        </div>
      </div>
      <div class="tsBox">
        <h1>产品特色</h1>
        <div class="listBox">
          <div class="item">
            <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/serverSeven/ts1.png" alt="">
            <div class="heng"></div>
            <span>保护主体公司，降低税务风险</span>
          </div>
          <div class="item">
            <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/serverSeven/ts2.png" alt="">
            <div class="heng"></div>
            <span>业务真实再现，各场景符合业务逻辑</span>
          </div>
          <div class="item">
            <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/serverSeven/ts3.png" alt="">
            <div class="heng"></div>
            <span>确保合理化享受国家政策福利</span>
          </div>
          <div class="item">
            <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/serverSeven/ts4.png" alt="">
            <div class="heng"></div>
            <span>优化财务结构及记账模型</span>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style scoped lang="less">
  h1{
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    text-align: center;
  }
  .light{
    background: #fff;
    padding-top:67px;
    padding-bottom: 74px;
    >div{
      margin-top:57px;
      display: flex;
      justify-content: center;
      >div{
        display: flex;
        align-items: center;
        span{
          font-size: 16px;
          font-weight: bold;
          color: #6687ED;
          padding-left: 27px;
        }
        
      }
    }
  }
  .desBox{
    padding-top:58px;
    padding-bottom:46px;
    background: #F6F6F6;
    >div{
      margin-top:48px;
      display: flex;
      .txtBox{
        width:44.64%;
        text-align:right;
        padding-right:4.32%;
        padding-top:4.06%;
        p{
          font-size: 14px;
          font-weight: 500;
          color: #333333;
          margin-bottom: 4%;
        }
      }
      .imgBox{
        width: 55.36%;
        img{
          width: 100%;
        }
      }
    }
  }

  .tsBox{
    padding-top:70px;
    padding-bottom: 80px;
    background: #FFFFFF;
    .listBox{
      display: flex;
      width: 1300px;
      margin: auto;
      margin-top:48px;
      .item{
        width: 312px;
        height: 285px;
        margin-left: 17px;
        background: #FFFFFF;
        box-shadow: -3px 4px 6px 1px rgba(211,211,211,0.21);
        .heng{
          margin-top:17px;
          margin-left:11px;
          width: 111px;
          height: 2px;
          background: #2E8BD0;
        }
        span{
          display: inline-block;
          font-size: 18px;
          font-weight: 500;
          color: #2E8BD0;
          margin-left:11px;
          margin-top:17px;
        }
      }
      .item:first-child{
        margin-left:0;
      }
    }
  }
</style>