import { render, staticRenderFns } from "./serveSeven.vue?vue&type=template&id=46c36316&scoped=true"
import script from "./serveSeven.vue?vue&type=script&lang=js"
export * from "./serveSeven.vue?vue&type=script&lang=js"
import style0 from "./serveSeven.vue?vue&type=style&index=0&id=46c36316&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46c36316",
  null
  
)

export default component.exports