<template>
    <div class="banner" :class="'bg_img_'+type">
        <div class="content">
            <div class="text_area" v-if="type==1">
                <h1 class="txtTit" style="color:#6A6B79">14项法律服务+金牌律师+2项增值服务</h1>
                <h3 class="txtTit_des" style="color:#7E819D">根据货代货运行业特点，协助解决企业在进出口运输货物过程中遇到的各类法律风险。</h3>
            </div>
            <div class="text_area" v-else-if="type==2">
                <h1 class="txtTit">22项法律服务+王牌律师+3项增值服务</h1>
                <h3 class="txtTit_des">结合海运、国际贸易、国际结算、保险等领域，由背景优秀和执业经验丰富的海商海事律师 <br> 提供专项及日常律实务服务</h3>
            </div>
            <div class="text_area" v-else>
                <div>
                    <img :src="`https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/banner/b_tit_${type}.png`" alt="">
                </div>
                <h2 class="small_tit">企业合规经营综合性服务平台</h2>
                <div class="FaButton"  @click="handleGet">{{ type==9?'立即咨询':'领取免费方案' }}</div>
                <small class="tips" v-if="type!=9">已有{{ down_num }}人下载该方案</small>
            </div>
            <div class="left_bottom" :class="{'color':type==1}" v-if="type==1 || type==2">企业风向管理研究中心</div>
        </div>
        <el-dialog
            class="ddlog"
            :visible.sync="dialogVisible"
            width="30%"
        >
            <div class="close el-icon-close" @click="dialogVisible=false"></div>
            <div class="iimgBox">
                <el-image :src="current_image" :preview-src-list="[current_image]"></el-image>
            </div> 
            
            
            <div class="btnBox">
                <el-button type="primary" :loading="loading"  style="width:50%" @click="handleDown">下载</el-button>
            </div>
            
        </el-dialog>
        <div class="home-dialog">
            <el-dialog :visible.sync="dialogVisibleToQRcode" width="418px" :close-on-click-modal="false">
                <div class="code-css">
                    <i class="el-icon-close code-close cursor" @click="codeClose"></i>
                    <div class="code-css-img">
                        <img src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/consultWx.jpg" alt="">
                    </div>
                    <div class="code-hint">扫描二维码查看</div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        type:{
            type:String | Number,
            default:3
        }
    },

    data(){
        return{
            dialogVisible:false,
            down_num:0,
            service_type:1,
            current_image:"",
            loading:false,
            dialogVisibleToQRcode: false,
            log_title:""
        }
    },
    mounted() {
        this.typeComputed()
        this.getCounts()
        if(this.type != 9){
            this.$log({
                topic:'comService',
                id:'comService.enter',
                content:JSON.stringify({title:this.log_title})
            })
        }
        
    },
    methods:{
        typeComputed(){
            /**
             *  3-->5
             *  4-->4
             *  5-->2
             *  6-->6
             *  7-->1
             *  8-->3
             *   -->
             */
            let service_type = ''
            let img = ''
            let log_tit = ''
            /**
             *  财税规划架构搭建        framework 
                财务基础建设        basic 
                预算管理体系建设        structure 
                财务分析体系建设        analysis 
                内控服务        control 
                财务报表审计（年报审计）        annual 
                优享        enjoy 
                尊享        exclusive
             * 
             */
            switch (this.type*1) {
                case 1: //优享
                    log_tit = "enjoy"
                    break;
                case 1: //尊享
                    log_tit = "exclusive"
                    break;
                case 3: //内控服务
                    log_tit = "control"
                    service_type = 5
                    img ='https://attached-file.oss-cn-shanghai.aliyuncs.com/aila3.0/appletsImages/digitalbusinessCard/companyService/icon42.jpg'
                    break;
                case 4: //财务分析体系建设
                    log_tit = "analysis"
                    service_type = 4
                    img = 'https://attached-file.oss-cn-shanghai.aliyuncs.com/aila3.0/appletsImages/digitalbusinessCard/companyService/icon41.jpg'
                    break;
                case 5: //财务基础建设
                    log_tit = "basic"
                    service_type = 2
                    img='https://attached-file.oss-cn-shanghai.aliyuncs.com/aila3.0/appletsImages/digitalbusinessCard/companyService/icon39.jpg'
                    break;
                case 6://财务报表审计（年报审计） 
                    log_tit = "annual"
                    service_type = 6 //////??????????
                    img = 'https://attached-file.oss-cn-shanghai.aliyuncs.com/aila3.0/appletsImages/digitalbusinessCard/companyService/icon42.jpg'
                    break;
                case 7://财税规划架构搭建
                    log_tit = "framework"
                    service_type = 1
                    img = 'https://attached-file.oss-cn-shanghai.aliyuncs.com/aila3.0/appletsImages/digitalbusinessCard/companyService/icon38.jpg'
                    break;
                case 8://预算管理体系建设
                    log_tit = "structure"
                    service_type = 3
                    img = 'https://attached-file.oss-cn-shanghai.aliyuncs.com/aila3.0/appletsImages/digitalbusinessCard/companyService/icon40.jpg'
                    break;
                case 9:
                    log_tit = ""
                    break;
                default:
                    break;
            }
            this.log_title = log_tit 
            this.service_type = service_type
            this.current_image = img
        },
        async handleGet(){
            

            

            if(this.type==9){ //高恒首页
                this.dialogVisibleToQRcode = true;
                this.$log({
                    topic:'comService',
                    id:'comService.immedConsulting'
                })
            }else{
                if (!this.IS_LOGIN) {
                    return this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
                }
                this.dialogVisible = true
                this.$log({
                    topic:'comService',
                    id:'comService.modeReceivePlan',
                    content:JSON.stringify({title:this.log_title})
                })
            }
        },
        codeClose() {
            this.dialogVisibleToQRcode = false;
        },
        async handleDown(){
            window.open(this.current_image)
        },
        async getCounts(){
            let params = {
                service_type:this.service_type,
                nopage:1
            }
            let res = await this.$store.dispatch("baseStore/getServerDowmLoadNums",params);
            if(res.success){
                this.down_num = res.count
            }
        }
    }
}
</script>
<style lang="less" scoped>
    .bg_img_1{
        background-image:url('https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/banner/bannder_1.png') ;
    }
    .bg_img_2{
        background-image:url('https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/banner/bannder_2.png') ;
    }
    .bg_img_3{
        background-image:url('https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/banner/bannder_3.png') ;
    }
    .bg_img_4{
        background-image:url('https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/banner/bannder_4.png') ;
    }
    .bg_img_5{
        background-image:url('https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/banner/bannder_5.png') ;
    }
    .bg_img_6{
        background-image:url('https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/banner/bannder_6.png') ;
    }
    .bg_img_7{
        background-image:url('https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/banner/bannder_7.png') ;
    }
    .bg_img_8{
        background-image:url('https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/banner/bannder_8.png') ;
    }
    .bg_img_9{
        background-image:url('https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/banner/bannder_9.png') ;
    }

    .banner {
      width: 100%;
      padding-top: 31.25%;
      background-size: 100% 100%;
      position: relative;
  }

  .txtTit{
    font-size: 45px;
    font-weight: bold;
    color: #83693A;
    padding-top: 2.45%;
  }

  .left_bottom{
    font-size: 20px;
    font-weight: 500;
    color: #83693A;
    position: absolute;
    right: 0;
    bottom: 1.46%;
  }

  .txtTit_des{
    padding-top: 3.07%;
    font-size: 22px;
    font-weight: 500;
    color: #83693A;
    line-height: 35px;
  }

  .color{
    color:#7E819D;
  }
  .banner .content {
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      width: 1300px;
      height: 100%;
      margin: 0 auto;
      padding-top: 8.02%;
  }

  .small_tit{
    font-size: 28px;
    font-weight: 500;
    color: #F2F4FF;
    padding-top:1.98%;
    padding-bottom: 2.24%;
  }
  .FaButton{
    width: 225px;
    height: 54px;
    background: linear-gradient(0deg, #FBEB7A, #F5CC49);
    box-shadow: 0px 4px 5px 0px rgba(161,87,31,0.43), -1px 0px 4px 0px rgba(255,255,255,0.74);
    border-radius: 27px;
    cursor: pointer;
    font-size: 25px;
    font-weight: bold;
    color: #97621C;
    line-height: 54px;
    text-align: center;
  }

  .tips{
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: #F2F4FF;
    padding-top: 1.35%;
  }


  /deep/ .ddlog .el-dialog__header{
    display: none;
  }
  /deep/ .el-dialog{
    background: rgba(220, 38, 38, 0);
    box-shadow:none;
  }
  /deep/ .ddlog .el-dialog__body{
    padding: 0;
    background: rgba(220, 38, 38, 0);
    position: relative;
  }
  .close{
    position: absolute;
    font-size:20px;
    z-index: 1;
    color: #eee;
    cursor: pointer;
    right:10px;
    top:10px;
  }
  .close:hover{
    font-weight: bold;
    color: #fff;
  }
  .btnBox{
    padding-top:10px;
    text-align: center;
  }
  .iimgBox{
    min-height: 610px;
  }
  .home-dialog{
    .code-css {
        position: relative;
        width: 418px;
        height: 427px;
        overflow: hidden;

        .code-css-img {
            width: 190px;
            height: 190px;
            margin: 0 auto;
            margin-top: 98px;

            >img {
                width: 100%;
                height: 100%;
            }
        }

        .code-close {
            font-size: 22px;
            position: absolute;
            font-weight: bold;
            color: #999999;
            top: 18px;
            right: 19px;
        }

        .code-hint {
            font-size: 18px;
            font-weight: 500;
            color: #999999;
            line-height: 1;
            margin-top: 64px;
            text-align: center;
        }
    }

    /deep/.el-dialog__header {
        display: none;
    }

    /deep/.el-dialog__body {
        width: 418px;
        height: 427px;
        padding: 0;
        background: #FFFFFF;
        box-shadow: 0px 3px 9px 1px rgba(143, 143, 143, 0.14);
        border-radius: 10px;
    }
  }
</style>