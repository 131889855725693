<template>
  <div class="box">
    <table cellspacing="0">
      <thead>
        <tr>
          <th>操作步骤</th>
          <th>产品介绍</th>
          <th>交付形式</th>
          <th>实施效果</th>
        </tr>
      </thead>
      <tbody>
        <tr class="bg_eee">
          <td>了解企业关键重要内<br>控流程的每一个关键<br>控制点如报销流程的<br>整𠆤流程走向</td>
          <td>检查审核企业运营过程中财务流程是否规范，管理是否存在漏洞，<br/>并给出相关意见</td>
          <td rowspan="2" style="background:#fafafa;border-right: 1px solid #eee;border-left: 1px solid #eee;">《内控评估报告》</td>
          <td rowspan="2" style="background:#fafafa;">【现金与银行存款(即报销与 费用支付)、销售-收款、<br/>采购-付款、存货-成本、人事-工资发放、税金-申<br/>报等六项中任选两项】流程梳理诊断，排查关键节点<br/>执行情况及潜在风险;</td>
        </tr>
        <tr>
          <td>穿行性测试检查运营流程是否按照企业规定的流桯迓仃流转，是台仔任制芨木严格执行的情况</td>
          <td style="border-right: 1px solid #eee;">检查企业关键流程的执行情况是否存在疏漏</td>
        </tr>
        <tr class="bg_eee">
          <td>符合性测试在穿行测试完成后，抽取部分梓本，检杳关键节点是否得到有效控制</td>
          <td>评价和监督企业财务内部控制的有效性，并给出相关意见。（制度是否健全有效、人员素质是否达标）检查审核企业资产的安全性并给出风险管控意见（资产归口、清查、盘点）</td>
          <td>管理建议书</td>
          <td>为企业测试内控流程是否有效执行、是否存在漏洞、是否需要优化，并提出整改建议;协助建立适合企业自身状况的内控流程。</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style scoped lang="less">
  .box{
    width: 1300px;
    margin: auto;
    padding-top:65px;
    padding-bottom:57px;
  }
  table{
    border-right:1px solid #EEEEEE;
    border-bottom:1px solid #EEEEEE;
  }
  table th,table td{
    border-left:1px solid #EEEEEE;
    border-top:1px solid #EEEEEE;
  }
  .bg_eee{
      background: #EEEEEE;
  }
  .bg_eee td{
    border-left:1px solid #FFFFFF
  }
  .bg_eee td:first-child{
    border-left:1px solid #EEEEEE !important;
  }
  tr{
    border-top:1px solid;
  }
  thead th{
    height: 90px;
    font-size: 20px;
    font-weight: bold;
    color: #080549;
    text-align: left;
    padding-left:15px;
  }
  thead th:first-child{
    width: 194px ;
  }
  thead th:nth-child(2){
    width:486px ;
  }
  thead th:nth-child(3){
    width: 232px ; 
  }
  thead th:nth-child(4){
    width: 385px ; 
  }
  tr{
    height: 110px;
  }
  tr>td{
    height: 140px;
    padding: 25px 15px;
    box-sizing: border-box;
    // padding-left: 15px;
    // padding-right: 15px;
    font-size: 14px;
    font-weight: bold;
    color: #211D1D;
    line-height: 24px;

  }
  tr>td:first-child{
    font-size: 18px;
    font-weight: bold;
    color: #211D1D;
  }
  .tips{
    margin-top:27px;
    font-size: 14px;
    font-weight: bold;
    color: #999999;
  }
</style>