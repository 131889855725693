<template>
  <div>
    <div class="table">
      <div class="top">
        <div class="titleLeft">操作步骤</div>
        <div class="titleCenter">产品介绍</div>
        <div class="titleRight">实施效果</div>
      </div>
      <div class="tbody1">
        <div class="oneContent">辅助战略（经营）<br/>目标的制定</div>
        <div class="twoContent">收集、编制历史财务数据，对历史各业务链条的收入、成本利润、现金流等做详尽分析，为企业战<br/>略(经营)目标制定提供依据</div>
        <div class="threeContent">各层级及各阶段目标明确，提高员工主观能动力，提升<br/>企业资源转化率</div>
      </div>
      <div class="tbody1 noBg">
        <div class="oneContent">  编制《预算管理制度》</div>
        <div class="twoContent">根据企业实际情况,编制相关制度，并对制度中的重要方面为企业做详细解读</div>
        <div class="threeContent">提高预算执行力,规范、保障预算有效执行</div>
      </div>
      <div class="tbody1">
        <div class="oneContent">预算启动会<br/>预算培训会</div>
        <div class="twoContent">培训会：培训预算的基本知识，统一整体预算管理过程中的每个环节，掌握填制方法、明确填制要求<br/>启动会：强调预算的重要性、必要性、启动时间、完成时间，并下达经营目标</div>
        <div class="threeContent">使企业全员重视预算，并行形成规范的预算管理制度，各部门能合理填制预算具备基本操作执行能力</div>
      </div>
      <div class="tbody1 noBg">
        <div class="oneContent">预算编制</div>
        <div class="twoContent">根据会计科目的不同和业务目标的不同设置各业务线不同的预算模型，设置各模型之间的勾稽关系和关联度，确保预算符合公司整体规划<br/>建立预算编制整体性思维，辅导各部门进行预算编写;并协助财务汇总整体预算数据</div>
        <div class="threeContent">定制化预算模板，呈现整体预算情况。战略目标向下分解为预算，年度预算编制形成考核指标。模板体现管报和财报在数据层面上有勾稽关系，使编制的预算紧密贴合业务目标，达到企业想要的效果</div>
      </div>
      <div class="tbody1">
        <div class="oneContent">顸算执行</div>
        <div class="twoContent">实时监控预算执行情况，加强管控力度，定期出具相关预算分析报告，给各部门同步预算执行情况，定期组织复盘，对预算执行情况进行评估</div>
        <div class="threeContent">分期间对预算进行分解,并与管报实际数进行对比分析，形成差异报告;并分析差异根因，促进企业科学调整经营方向、优化经营模式;形成预实结合的激励循环，充分发挥预算管理的功能。实现预算与实际数横向的可比性，更好的达到完成预算的目的</div>
      </div>
      <div class="tbody1 noBg">
        <div class="oneContent">顸算考评</div>
        <div class="twoContent">建立一套能有效实现评价指标、目的、目标三者有机统-的预算执行绩效考核标准，高效的统筹分配现有资源，进而实现绩效评估的科学性和有效性</div>
        <div class="threeContent">将奖惩与激励并举形成正向的考核激励机制</div>
      </div>
      <div class="tbody1 noBg">
        <div class="oneContent">交付形式</div>
        <div class="twoContent">1.《预算管理制度》+启动会议/培训 2.定制《预算编制模板》3.《预算执行情况分析表》4.《预算执行经营分析报告》</div>
        <div class="threeContent" style="display: none;"></div>
      </div>
    </div>
    <div class="tip">交付形式：1、《预算管理制度》+启动会议/培训     2、定制《预算编制模板》   3、《预算执行情况分析表》    4、《预算执行经营分析报告》</div>
  </div>
</template>

<script>
import Banner from "./banner"
export default {
  data() {
    return {};
  },
  components: {
    Banner
  },
  created() {
  },
};
</script>

<style scoped lang="less">
.banner {
  width: 100%;
  height: 600px;
  background-color: aquamarine;
}

.table {
  width: 1300px;
  // height: 540px;
  margin: 66px auto 0;
  border-top: 1px solid #eee;
  border-left: 1px solid #eee;

  .top {
    display: flex;
    height: 92px;
    box-sizing: border-box;

    .titleLeft {
      width: 197px;
      padding-left: 23px;
      padding-top: 40px;
      box-sizing: border-box;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #080549;
      border-right: 1px solid #eee;
      border-bottom: 1px solid #eee;
    }

    .titleCenter {
      width: 684px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      border-right: 1px solid #eee;
      border-bottom: 1px solid #eee;
      padding-top: 40px;
      padding-left: 34px;
      box-sizing: border-box;
    }

    .titleRight {
      width: 420px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      border-right: 1px solid #eee;
      border-bottom: 1px solid #eee;
      padding-top: 40px;
      padding-left: 42px;
      box-sizing: border-box;
    }
  }

  .tbody1 {
    display: flex;
    height: 111px;
    background: #eee;

    .oneContent {
            display: flex;
      align-items: center;
      width: 197px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #211D1D;
      padding-left: 23px;
      // padding-top: 20px;
      border-right: 1px solid #fff;
      border-bottom: 1px solid #eee;
      box-sizing: border-box;
    }

    .twoContent {
            display: flex;
      align-items: center;
      width: 684px;
      font-size: 14px;
      font-weight: bold;
      color: #211D1D;
      border-right: 1px solid #fff;
      border-bottom: 1px solid #eee;
      padding: 0px 0 0 35px;
      box-sizing: border-box;
    }

    .threeContent {
      display: flex;
      align-items: center;
      width: 420px;
      font-size: 14px;
      font-weight: bold;
      color: #211D1D;
      border-right: 1px solid #eee;
      border-bottom: 1px solid #eee;
      padding: 0px 0 0 42px;
      box-sizing: border-box;
    }

  }

  .noBg {
    background: none;
    .oneContent,.twoContent,.threeContent {
      border-color: #eee!important;
    }
  }
}

.tip {
  width: 1300px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #999999;
  text-align: left;
  margin: 0 auto;
  margin-top: 27px;
  margin-bottom: 57px;
}
</style>