<template>
  <div style="background: #fafafa; padding-top: 66px">
    <div class="content">
      <div class="way">
        <img
          class="lay"
          src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworld/enterpriseServices/home/law.png"
          alt=""
        />
        <div class="right">
          <div class="box">
            <div class="title">
              <div class="line"></div>
              <div class="text">
                <div>服务方式</div>
                <div>service mode</div>
              </div>
              <div class="line2"></div>
            </div>
          </div>
          <div class="box">
            <div class="item">
              <div class="label">服务团队</div>
              <div class="value">由合伙人级别的律师团队对接</div>
            </div>
            <div class="item">
              <div class="label">服务时间</div>
              <div class="value">工作时间电话咨询(8:30-18:30）</div>
            </div>
            <div class="item">
              <div class="label">服务方式</div>
              <div class="value">简单法律事务:电话、邮件、微信咨询<br/>复杂法律事务:经服务团队研讨后形成书面法律意见</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 服务内容 -->
      <div style="position: relative;height: 170px;">
        <div class="title center">
          <div class="line"></div>
          <div class="text">
            <div>服务内容</div>
            <div>service content</div>
          </div>
          <div class="line2"></div>
        </div>
      </div>
      <div class="serveLay">        
        <div class="layItem">
          <div class="top">日常运营法律咨询</div>
          <div class="connect1"></div>
          <div class="connect2"></div>
          <div class="bottom">
            <div class="text">对企业日常事务（劳动人事、内容规划、业务事故、项目决策等）提供法律服务</div>
          </div>
        </div>
        <div class="layItem">
          <div class="top">合同文本</div>
          <div class="connect1"></div>
          <div class="connect2"></div>
          <div class="bottom">
            <div class="text">审核、 修改企业日常业务往来中的合同（如货运代理合同、运输合同、租船/订舱合同等，8份/年，限中文）根据企业业务拟定模板合同（2份/年，限中文)起草、审核、修改员工劳动合同、劳务合同等劳动用工类合同（限1份/年）</div>
          </div>
        </div>
        <div class="layItem">
          <div class="top">劳动人事</div>
          <div class="connect1"></div>
          <div class="connect2"></div>
          <div class="bottom">
            <div class="text">对企业已有劳动用工制度进行合规审查、风险评估起草、审核、修改员工劳动合同、劳务派遣协议等企业用工类合同（1份/年）审核、修改保密协议、竞业限制协议等相关合同（1份/年）对企业员工入职、在职、离职的管理流程相关法律问题提供合规建议对企业人员优化或裁员，提供法律问题咨询服务根据企业需要，提供劳动用工专项法律培训 （1次/年）</div>
          </div>
        </div>
        <div class="layItem">
          <div class="top" style="text-align:center;">海关、进出口贸易风险<br/>防控</div>
          <div class="connect1"></div>
          <div class="connect2"></div>
          <div class="bottom">
            <div class="text">对进出口业务环节、操作模式进行合规审查、风险评估对进出口贸易中货损货差、突发事件（扣货扣单、贸易欺诈等）提供法律处理建议对应收账款催收提供法律建议对报关、海关查验、扣货、行政处罚等提供法律处理建议</div>
          </div>
        </div>
        <div class="layItem">
          <div class="top">货代业务风险防控</div>
          <div class="connect1"></div>
          <div class="connect2"></div>
          <div class="bottom">
            <div class="text">对代理运输业务环节、操作模式进行合规审查、风险评估对转委托、上下游业务风险衔接/风险转移提供法律建议对海、陆、空、铁运输途中货损货差、业务事故处理提供法律支持对扣货扣单、无单放货、提单欺诈等突发事件提供法律建议</div>
          </div>
        </div>
      </div>
      <!-- 增值服务 -->
      <div>
        <div class="serveTit">增值服务</div>
        <div class="introduce">
          <div>根据企业需要，出具《律师西》或《法律意见书》（1份/年）</div>
          <div>根据企业需要，定制法律培训（1次/年）</div>
          <div>开放团队律师线下/线上讲座（劳动、货运、保险、贸易、海关、税务、企业刑事等）</div>
        </div>
      </div>
      <!-- 特别说明 -->
      <div>
        <div class="serveTit">特别说明</div>
        <div class="introduce">
          <div>以上报价将依据企业需求、企业规模、选择服务内容、工作量、难易复杂程度等因素会相应浮动，最终以《法律顾问合同》的服务内容及价格为准。</div>
          <div>年度服务时间：不超过50小时。签订保密条款/协议，律师对服务过程中接触到的企业信息负有保密义务。</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped lang="less">
.content {
  width: 1300px;
  margin: 0 auto;

  .center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    padding: 66px 0 42px 0;
  }

  .title {
    .line {
      width: 60px;
      height: 1px;
      background: #d3d3d3;
    }

    .line2 {
      width: 60px;
      height: 1px;
      background: #d3d3d3;
      margin-left: 150px;
    }

    .text {
      font-size: 22px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      margin: 12px 0 12px 60px;
      div {
        &:last-child {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #999999;
        }
      }
    }
  }

  .way {
    display: flex;

    .lay {
      width: 592px;
      height: 311px;
      margin-right: 13px;
    }

    .right {
      width: 695px;
      background: #f4f5f7;

      .box {
        margin: 51px 0 0 61px;

        .item {
          display: flex;
          font-size: 14px;
          margin-bottom: 10px;
          .label {
            color: #999999;
            margin-right: 20px;
          }
          .value {
            color: #636363;
          }
        }
      }
    }
  }

  .serveLay {
    display: flex;
    margin-bottom: 40px;
  }

  .layItem {
    position: relative;
    width: 250px;
    margin-right: 12px;
    .top {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 74px;
      background: #FFFFFF;
      border-radius: 5px;
      font-size: 20px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      margin-bottom: 20px;
    };

    .connect1 {
      position: absolute;
      top: 66px;
      left: 40px;
      width: 8px;
      height: 40px;
      background: #DDDDDD;
      border-radius: 3px;
    }

    .connect2 {
      position: absolute;
      top: 66px;
      right: 40px;
      width: 8px;
      height: 40px;
      background: #DDDDDD;
      border-radius: 3px;
    }
    
    .bottom {
      padding: 30px 18px 8px;
      height: 190px;
      background-color: #fff;
      overflow:hidden;
    }

    .text {
      display: inline-block;
      height: 100%;
      box-sizing: border-box;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #646464;
      line-height: 20px;
      overflow-y: auto;
    }

    .text::-webkit-scrollbar {
      display: none;
    }
  }

  .serveTit {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
  }

  .introduce {
    font-size: 14px;
    font-weight: 500;
    color: #999999;
    margin-top: 25px;
    margin-left: 48px;
    margin-bottom: 32px;

    div {
      margin-bottom: 10px;
    }
  }
}
</style>