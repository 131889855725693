<template>
  <div class="box">
    <table cellspacing="0">
      <thead>
        <tr>
          <th>步骤</th>
          <th>产品介绍</th>
          <th>实施效果</th>
        </tr>
      </thead>
      <tbody>
        <tr class="bg_eee">
          <td>前期调研</td>
          <td>调研企业经营模式及运营流程，判断业务开展流程中关键控制点的合理性，对企业现存及潜在的财务风险进行提示，进而优化或补充；</td>
          <td>排查企业运营及管控流程的上财务现状，做出风险提示及调整建议</td>
        </tr>
        <tr>
          <td>制度编制</td>
          <td>为企业量身定制三大制度：《财务岗位职责》《资金管理制度》《报销付款制度》；从制度上和流程上完善企业内部控制及风险管控体系，并对三大制度关键点解读及实施指导；</td>
          <td>帮助企业实现财务制度化管理</td>
        </tr>
        <tr class="bg_eee">
          <td>运营检验</td>
          <td>为企业开展运营风险识别并作分析；定期回顾及检验企业制度执行情况，适当修订优化及升级；</td>
          <td>协助企业落地制度管理，并识别运营风险</td>
        </tr>
        <tr>
          <td>薪税管理</td>
          <td>在社保入税的大形势下，辨别企业人力薪酬及个税方面的风险，同时判断企业人工税负及社保成本是否合理；按照企业的目标和需求进行匹配，对企业员工薪酬结构的优化或调整提出建议；</td>
          <td>帮助企业合理发放薪酬，规避个税风险及用工风险</td>
        </tr>
      </tbody>
    </table>
    <div class="tips"> 交付文件: 1.《账务风险诊断报告》  2.制度文件（压缩包）3.《运营风险评估报告》  4.《薪税服务报告》</div>
  </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style scoped lang="less">
  .box{
    width: 1300px;
    margin: auto;
    padding-top:65px;
    padding-bottom:57px;
  }
  table{
    border-right:1px solid #EEEEEE;
    border-bottom:1px solid #EEEEEE;
  }
  table th,table td{
    border-left:1px solid #EEEEEE;
    border-top:1px solid #EEEEEE;
  }
  .bg_eee{
      background: #EEEEEE;
  }
  .bg_eee td{
    border-left:1px solid #FFFFFF
  }
  .bg_eee td:first-child{
    border-left:1px solid #EEEEEE !important;
  }
  tr{
    border-top:1px solid;
  }
  thead th{
    height: 90px;
    font-size: 20px;
    font-weight: bold;
    color: #080549;
    text-align: left;
    padding-left:15px;
  }
  thead th:first-child{
    width: 194px ;
  }
  thead th:nth-child(2){
    width:684px ;
  }
  thead th:nth-child(3){
    width: 422px ; 
  }
  tr{
    height: 110px;
  }
  tr>td{
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #211D1D;
    line-height: 24px;

  }
  tr>td:first-child{
    font-size: 18px;
    font-weight: bold;
    color: #211D1D;
  }
  .tips{
    margin-top:27px;
    font-size: 14px;
    font-weight: bold;
    color: #999999;
  }
</style>