<template>
  <div class="box">
    <table cellspacing="0">
      <thead>
        <tr>
          <th>操作步骤</th>
          <th>产品介绍</th>
          <th>实施效果</th>
        </tr>
      </thead>
      <tbody>
        <tr class="bg_eee">
          <td>建立领导驾驶舱</td>
          <td>
            结合企业实际情况，制作领导驾驶舱，通过不同维度的财务数据分析，让财务数据成为提供经营决策解决方案的工具。让企业管理层直观掌握公司经营状况，及时评估企业的增长战略是否可持续。
          </td>
          <td rowspan="6">
            通过数据可视化技术，帮助高层管理者全面掌控企业的财务状况以及相关财务数据，实现业财联动，更精准的做出科学决策，从而把握正确的企业发展方向，实现经济效益最大化</td>
        </tr>
        <tr>
          <td>各部门分析</td>
          <td>分析整体业务数据以及费用支出情况，对净利润率进行分解，评估各部门经营管理效率。为企业的成本控制提升运营能力提供决策依据</td>
          
        </tr>
        <tr class="bg_eee">
          <td>产品分析</td>
          <td>根据企业所细分的不同类型产品收入，以及相匹配的成本，分析每项产品收入的毛利及性价比，分析每个产品的贡献度，为经营者后期调整产品结构及侧重点提供决策依据。</td>
          
        </tr>
        <tr>
          <td>客户分析</td>
          <td>根据对企业客户变化进行分析，分析重点客户、新增客户、留存客户等相关数据变化，以及客户对企业的贡献率，为企业下阶段的经营决策，提高企业盈利能力提供参考依据。</td>
        </tr>
        <tr class="bg_eee">
          <td>其他指标分析</td>
          <td>了解企业财务状况的全貌以及各项财务分析指标之间的结构关系，挖掘隐藏风险，查明各项财务指标增减变动的影响因素及其存在的问题，完善企业财务分析模型，优化分析体系。</td>
        </tr>
        <tr>
          <td>经营分析会及领导层培训</td>
          <td>对整个模型中数据勾稽情况及数据逻辑，对企业相关人员进行培训及指导，使模型使用者充分了解模型、利用模型，从而达到为经营分析提供有效工具的作用辅助各部门业务经营分析，提出需调整和改善的建议。</td>
        </tr>
      </tbody>
    </table>
    <div class="tips"> 交付形式: 领导驾驶舱 + 经营分析会财务数据 +领导层财务培训</div>
  </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style scoped lang="less">
  .box{
    width: 1300px;
    margin: auto;
    padding-top:65px;
    padding-bottom:57px;
  }
  table{
    border-right:1px solid #EEEEEE;
    border-bottom:1px solid #EEEEEE;
  }
  table th,table td{
    border-left:1px solid #EEEEEE;
    border-top:1px solid #EEEEEE;
  }
  .bg_eee{
      background: #EEEEEE;
  }
  .bg_eee td{
    border-left:1px solid #FFFFFF
  }
  .bg_eee td:first-child{
    border-left:1px solid #EEEEEE !important;
  }
  tr{
    border-top:1px solid;
  }
  thead th{
    height: 90px;
    font-size: 20px;
    font-weight: bold;
    color: #080549;
    text-align: left;
    padding-left:15px;
  }
  thead th:first-child{
    width: 194px ;
  }
  thead th:nth-child(2){
    width:684px ;
  }
  thead th:nth-child(3){
    width: 422px ; 
  }
  tr{
    height: 110px;
  }
  tr>td{
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #211D1D;
    line-height: 24px;

  }
  tr>td:first-child{
    font-size: 18px;
    font-weight: bold;
    color: #211D1D;
  }
  .tips{
    margin-top:27px;
    font-size: 14px;
    font-weight: bold;
    color: #999999;
  }
</style>