<template>
    <div>
        <div>
            <banner :type="type"></banner>
        </div>
        <serveOne v-if="type == 1" />
        <serveTwo v-if="type == 2"  />
        <serveThree v-if="type == 3" />
        <serveFour v-if="type == 4"  />
        <serveFive v-if="type == 5"  />
        <serveSix v-if="type == 6"  />
        <serveSeven v-if="type == 7"  />
        <serveEight v-if="type == 8" />
    </div>
</template>
<script>
import banner from "./compontents/banner"
import serveOne from "./compontents/serveOne"
import serveTwo from "./compontents/serveTwo"
import serveThree from "./compontents/serveThree"
import serveFour from "./compontents/serveFour"
import serveFive from "./compontents/serveFive"
import serveSix from "./compontents/serveSix"
import serveSeven from "./compontents/serveSeven"
import serveEight from "./compontents/serveEight"
export default {
    components:{
        banner,
        serveOne,
        serveTwo,
        serveThree,
        serveFour,
        serveFive,
        serveSix,
        serveSeven,
        serveEight
    },
    data(){
        return{
            type: 1
        }
    },
    metaInfo() {
        let title=''
        if(this.type == 1) {
            title = '法律服务优享'
        } else if(this.type == 2) {
            title = '法律服务尊享'
        } else if(this.type == 3) {
            title = '内控服务'
        } else if(this.type == 4) {
            title = '财务分析体系建设'
        } else if(this.type == 5) {
            title = '财务基础建设'
        } else if(this.type == 6) {
            title = '财税报表审计'
        } else if(this.type == 7) {
            title = '财税规划构建搭建'
        } else if(this.type == 8) {
            title = '预算管理体系建设'
        }
        return {
            title,
        };
    },
    created(){
        if(this.$route.query.type){
            this.type = this.$route.query.type
        }
    }
}
</script>